<template>
    <div class="blogpost" data-view>
        <div class="container">
            <div class="title">
                <p><img loading="lupa" alt="miro" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/lupa-executive2.png">BLOG <span> ENTRY</span></p>
            </div>
            <b-row class="rowas">
                <div class="head" v-if="post != ''">
                    <div class="contenttitle">
                        <h1 class="headertext" style="margin-top: 10px;">{{ post.title }}</h1>
                        <div class="authorRow">
                            <div class="authorinfo">
                                <img loading="lazy" alt="miro" class="author" src="https://miro.medium.com/fit/c/80/80/1*5rYPlutRlZk8Lcs_dqr4sA.png"/>
                                <div class="authorText">
                                    <p v-if="post.users">{{ post.users.name }}</p>
                                    <p class="date">{{ post.created_at }}</p>
                                </div>
                            </div> 
                            <div class="actions" v-if="$auth.check()">
                                <font-awesome-icon v-on:click.stop="edit(post)" :icon="['fas', 'edit']" class="socialIcon edit"/>
                                <font-awesome-icon v-on:click.stop="dangerModal = true;" :icon="['fas', 'trash']" class="socialIcon delete"/>
                            </div>                     
                        </div>
                    </div>
                    <img loading="lazy" alt="post" class="contentImg" v-if="post.image" :src="baseUrl + post.image"/>
                    <div class="content">
                        <p v-html="post.content"></p>
                    </div>
                </div>
                <div class="head" v-else>
                    <b-alert show variant="warning">Blog does not exist!</b-alert>
                </div>
            </b-row>
            <b-row class="clearfix" style="height: 50px;"></b-row>
        </div>
        <b-modal 
            title="Are you sure you want to delete?" 
            header-bg-variant="danger" 
            header-text-variant="light"  
            centered  
            size="md" 
            v-model="dangerModal" 
            @ok="dangerModal = false; destroy(post)"
        >
          You will not be able to recover it later!
        </b-modal>
    </div> 
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
    name: 'blogpost',
    metaInfo() {
        return {
            title: this.post.title + ' - Blog ',
            meta: [
                // OpenGraph
                { vmid: "og:title", property: "og:title",  content: this.post.title + ' - Club de Ejecutivos' },
                { vmid: "og:type", property: 'og:type', content: 'website' },
                { vmid: "og:image", property: 'og:image', content: window.location.origin + '/images/blogimages/' + this.post.image },
                { vmid: "og:description", property: 'og:description', content: this.short_description(this.post.content) },
                // Google / Schema.org markup:
                { itemprop: 'name', content: this.post.title + ' - Club de Ejecutivos' },
                { itemprop: 'description', content: this.short_description(this.post.content) },
                { itemprop: 'image', content: window.location.origin + '/' + this.post.image }
            ]
        }
        
    },
    data() {
        return {
            dangerModal: false,
            notStored: true,
            post: '',
            baseUrl: '',
        }
    },
    computed: mapState({
        blogs: state => state.blogs.all
    }),
    methods: {
        edit(blog) {
            this.$router.push({ name: 'editblog', params: { id: blog.id } });
        },
        destroy(blog) {
            this.$store.dispatch('blogs/destroyBlog', blog.id ).then( () => {
                this.$router.push({ name: 'adminblog', params: {alert: 'deleted'} })
            });
        },
        short_description(content) {
            const regex = /<(\p)>(.*?)<\/\1>/;
            const matches = regex.exec(content);
            if(matches) {
                let l = matches[0].length;
                if(l < 200) {
                    let reg = '/^(.{' + l + '}[^\s]*).*/'
                    return matches[0].replace(reg, "$1").replace('<p>', '').replace('</p>', '') + '...';
                } else{
                    return matches[0].replace(/^(.{200}[^\s]*).*/, "$1").replace('<p>', '').replace('</p>', '') + '...';
                }
            } else {
                return '';
            }
        },
    },
    async mounted() {
        this.notStored = await this.$store.dispatch('blogs/getOneBlog', this.$route.params.id);
        if(this.notStored) {
            this.post = this.$store.getters['blogs/getBlogById'](this.$route.params.id);
        } else {
            this.post = this.blogs[0];
        }

        process.env.NODE_ENV == 'development' ? 
            this.baseUrl =  'https://clubejecutivo.aicad.es/blogimages/' : 
            this.baseUrl = '/images/blogimages/'
    },

}
</script>

<style scoped>
    .rowas {
        width: 100%;
    }

    .head {
        text-align: left;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }

    .contentp {
        color: rgba(0,0,0,.54);
        font-size: 24px;
    }

    .content p {
        font-family: "Times New Roman",Times,serif;
        letter-spacing: .01rem;
        font-weight: 400;
        font-style: normal;
        font-size: 21px;
        line-height: 1.58;
        letter-spacing: -.003em;
    }

    .content {
        width: 80%;
        margin-right: auto;
        margin-left: auto;
    }

    .head .contentImg {
        width: 100%;
        height: auto;
        margin: 20px 0px 40px;
    }

    .author {
        border-radius: 50%;
        width: 55px !important;
    }

    .authorinfo {
        display: inline-block;
        vertical-align: unset;
        margin-left: 10px;
    }

    .authorinfo p {
        margin-bottom: 0px;
        color: rgba(0, 0, 0, 0.84);
        font-size: 15px;
    }
    .authorinfo .date {
        color: rgba(0, 0, 0, 0.54);
        font-size: 13px;
    }

    .head .contenttitle {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    .authorText {
        display: inline-block;
        vertical-align: middle;
        margin-left: 10px;
    }

    .authorRow {
        display: flex;
        justify-content: space-between;
    }

    .socialIcon {
        margin-left: 10px;
        transition: 0.3s ease;
        cursor: pointer;
    }

    .socialIcon.edit:hover {
        color: #ffc107;
        transform: scale(1.1);
        transition: 0.3s ease;
    }

    .socialIcon.delete:hover {
        color: #dc3545;
        transform: scale(1.1);
        transition: 0.3s ease;
    }

    .actions {
        margin-top: auto;
        margin-bottom: auto;
    }
</style>